import React from 'react'
import GoogleMapReact from 'google-map-react'
import Marker from './Marker'

export default function SimpleMap() {
  const defaultProps = {
    center: {
      lat: 48.83992061992211,
      lng: 6.066062800156183,
    },
    zoom: 17,
  }

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '80vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyAlQUDmav1FbyDJp2RMA3aHVtw57EYaq_k',
          v: '3.32',
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        draggable={false}
      >
        <Marker />
      </GoogleMapReact>
    </div>
  )
}
